.lesson-times-container {
  text-align: center;
  margin: 20px 20px 100px;
}

.lesson-times-list {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.lesson-card {
  min-width: 200px;
}

.lesson-time-button {
  margin: 4px 0 !important;
}
.cancel-button {
  background-color: red;
}
.lesson-time-button:focus {
  outline: none;
}

.participants-button {
  text-decoration: underline;
}

.add-new-booking {
  text-align: center;
}

.new-booking-select {
  width: 75%;
  margin-bottom: 8px;
}

