.plan-select{
  max-width: 100px;
}

.list-box {
  margin-bottom: 64px;
}

.student-searcher {
  width: 100%;
}
