.Mui-selected {
  color: white;
  svg {
    color: white !important;
  }
}

.tabs-container {
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: black;
}
